import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { createGlobalStyle } from 'styled-components';

import Version from '../app-v2/components/version';

import AnalyticsContextProvider from './analytics';
import { ErrorFallback } from './components/error-fallback/error-fallback';
import { Loading } from './components/loading/loading';
// import ReloadPrompt from './components/reload-prompt/reload-prompt';
import ReloadPrompt from './components/reload-prompt/reload-prompt';
import { Paths } from './constants/paths';
import CRApp from './pages/cr-app';
import Demo from './pages/demo';
// import Home from './pages/home';
import { ErrorPage, PageNotFound } from './pages/not-found/error-page';
import { Privacy } from './pages/privacy';
import { TermsAndConditions } from './pages/terms';

const GlobalStyle = createGlobalStyle`
  html,body {
    margin:0;
    padding:0;
    font-family: 'Space Grotesk', 'Open Sans', sans-serif;
    scroll-behavior: smooth;
  }

  button {
    font-family: 'Space Grotesk', 'Open Sans', sans-serif;
  }
`;

export const App: React.FC = () => {
  const { boot } = useIntercom();
  const { getVisitorId } = useIntercom();

  useEffect(() => {
    boot();
  }, [boot, getVisitorId]);

  return (
    <React.Suspense fallback={<Loading />}>
      <AnalyticsContextProvider
        platform={'segment'}
        writeKey={import.meta.env.REACT_APP_SEGMENT_WRITE_KEY || ''}
      >
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
        >
          <GlobalStyle />
          <Version>
            <ReloadPrompt />
            <Routes>
              <Route path={Paths.HOME} element={<Navigate to="/app/redirect" replace />} />
              <Route path={Paths.PRIVACY_POLICY} element={<Privacy />} />
              <Route path={Paths.TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
              {import.meta.env.REACT_APP_IS_ISOLATED_ENV == 'false' && (
                <Route path={'demo/*'} element={<Demo />} />
              )}
              <Route path={'app/*'} element={<CRApp />} />
              <Route
                path={Paths.NOT_FOUND}
                element={
                  <ErrorPage>
                    <PageNotFound />
                  </ErrorPage>
                }
              />
            </Routes>
          </Version>
        </ErrorBoundary>
      </AnalyticsContextProvider>
    </React.Suspense>
  );
};

export default App;
