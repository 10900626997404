import { useEffect, useState } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line
import { registerSW } from 'virtual:pwa-register';

const ReloadPromptContainer = styled.div`
  background-color: #393939;
  color: #fff;
  padding: 0;
  margin: 0px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;
`;
const ReloadPromptToast = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 16px;
  padding: 12px;
  border: 1px solid #8885;
  border-radius: 4px;
  z-index: 1;
  text-align: left;
  box-shadow: 3px 4px 5px 0 #8885;
  background-color: #393939;
`;

const ReloadPromptToastMessage = styled.div`
  margin-bottom: 8px;
`;

const ReloadPromptToastButton = styled.button`
  border: 1px solid #8885;
  outline: none;
  margin-right: 5px;
  border-radius: 2px;
  padding: 3px 10px;
`;

const intervalMS = 5000;
// const intervalMS = 60 * 60 * 1000;

const ReloadPrompt = () => {
  // When a new service worker is ready, we'll set this to true
  const [needRefresh, setNeedRefresh] = useState(false);

  // We'll store the updateSW function returned from registerSW,
  // so we can call it later to activate the new SW
  const [updateServiceWorker, setUpdateServiceWorker] = useState<
    null | ((reloadPage?: boolean) => Promise<void>)
  >(null);

  useEffect(() => {
    // Register the service worker via the plugin's utility
    const updateSW = registerSW({
      // This fires when there's a new SW waiting to activate
      onNeedRefresh() {
        console.log('New version available: needRefresh = true');
        setNeedRefresh(true);
      },
      // Optional
      onOfflineReady() {
        // console.log('The app is ready to work offline.');
      },
      // Optional
      onRegisteredSW(swUrl, registration) {
        if (registration) {
          registration.addEventListener('updatefound', () => {
            console.log('Service Worker update found!');
          });
        }
        if (registration) {
          setInterval(() => {
            console.log('calling---- in interval');
            registration.update();
          }, intervalMS); // 1 hour
        }
        console.log('SW registered:', swUrl, registration);
      },
      // Optional
      onRegisterError(error) {
        console.error('SW registration error:', error);
      },
    });

    // Save the updateSW function so we can call it later
    setUpdateServiceWorker(() => updateSW);
  }, []);

  // Called when the user clicks "Reload" in our prompt
  const handleReload = async () => {
    if (updateServiceWorker) {
      // Clear all cached files
      if ('caches' in window) {
        const cacheNames = await caches.keys();
        await Promise.all(cacheNames.map((cache) => caches.delete(cache)));
        console.log('All caches cleared!');
      }

      // Pass `true` to skip waiting and activate the new SW immediately
      await updateServiceWorker(true);
      // Then reload the page to load the fresh content
      window.location.reload();
    }

    // If there's no new service worker, don't show the prompt
    if (!needRefresh) return null;
  };

  const close = () => {
    setNeedRefresh(false);
  };

  if (!needRefresh) return null;

  return (
    <ReloadPromptContainer className="reload-prompt">
      <ReloadPromptToast>
        <ReloadPromptToastMessage>
          <span>New content available, click on reload button to update.</span>
        </ReloadPromptToastMessage>
        <ReloadPromptToastButton onClick={handleReload}>Reload</ReloadPromptToastButton>
        <ReloadPromptToastButton onClick={close}>Close</ReloadPromptToastButton>
      </ReloadPromptToast>
    </ReloadPromptContainer>
  );
};

export default ReloadPrompt;
