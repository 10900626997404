import { Composition } from 'atomic-layout';
import React, { useContext, useMemo } from 'react';

import { Role } from '../../../app/constants/auth';
import { AuthorizationContext } from '../../../app/context/authorization-context';
import BrowserFilter from '../../components/browser-filter/browser-filter';
import { ChartHeader } from '../chart-header/chart-header';
import { HeaderWrapper } from '../header';
import { usePanelContext } from '../layout/Panel';
import { TimeDisplay } from '../time-display/time-display';

import { Filtering } from './filtering';
import SeverityFilter from './severity-filter';
import LimitsToggle from './severity-limit-exceeded-toggle';
import { StyledFilterContainer } from './styles';

const areasMobile = `
sidebar content
`;

export const MonitorHeader: React.FC = () => {
  const { sidebarWidth } = usePanelContext();
  const { hasRole } = useContext(AuthorizationContext);
  const hasLabelingRole = useMemo(
    () => hasRole(Role.GLOBAL_LABEL_EDITOR) || hasRole(Role.GLOBAL_LABEL_VIEWER),
    [hasRole],
  );
  return (
    <Composition
      areas={areasMobile}
      gap={0}
      templateCols={`${sidebarWidth}px 1fr`}
      templateColsLg={`${sidebarWidth}px 1fr`}
    >
      {(Areas) => (
        <>
          <Areas.Sidebar>
            <HeaderWrapper>
              <TimeDisplay />
            </HeaderWrapper>
            <StyledFilterContainer>
              <BrowserFilter />
              <div className="sort">By System:</div>
            </StyledFilterContainer>
          </Areas.Sidebar>
          <Areas.Content flexGrow="1" flexDirection="column">
            <ChartHeader>
              <SeverityFilter showSlider={true} />
              {hasLabelingRole ? <Filtering /> : null}
              <LimitsToggle />
            </ChartHeader>
          </Areas.Content>
        </>
      )}
    </Composition>
  );
};
